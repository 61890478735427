<template>
<section class="wrapper bg-light">
	<div class="container py-14 py-md-16">
		<div class="row align-items-center mb-10">

			<h2 class="display-4 mb-0">
				Les transferts hors bockchain via le tiers de confiance</h2>
		</div>
		<h2>
			Principe de base</h2>
		<p>
			Toutes les opérations ne peuvent pas forcément passer par la blockchain et l'utilisation d'un smart contract de séquestre est la solution décentralisée pour mixer la sécurité de la blockchain et la garantie d'exécution dans le monde réel.

			<br /> <br />
			Le principe est simple : un dépôt est fait sur un compte de séquestre et le smart contrat envoi un token de déblocage à chacune des deux parties prenantes.
			Lorsque la contrepartie dans le monde réel est réalisée, celui qui a fait le dépôt envoie son token de déblocage au bénéficiaire.
			Inversement, si la contrepartie ne peut pas se réaliser, le bénéficiaire retourne le token de déblocage à l'envoyeur.
			<br /> <br /> Celui qui dispose des deux tokens de déblocage récupère le séquestre.

		</p>
		<h2>
			Un exemple concret</h2>
		<p>
			Nathalie et Valérie ont été mis en relation via un réseau social, elles ne se connaissent pas réellement.
			<br />Nathalie écrit un conte fantastique et Valérie est graphiste.
			Nathalie est très intéressée par les illustrations réalisées par Valérie. Un accord est trouvé, Nathalie va réaliser 6 illustrations pour le conte de Valérie en échange de 12 Berlcoins.
			<br />
		</p>

		<h3 class="mt-12">
			Situation initiale</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-1.png" />
				<br /> <br /> <b> Le compte de Nathalie</b><br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-1.png" /><br /> <br /><b>Le compte de Valérie </b></div>
		</div>


		<h3 class="mt-12">
			La mise sous séquestre de 12 berlcoins par Nathalie</h3>

		<div class="row ">
			<div class="col-6 col-md-6 mb-4">
				<img style="border: 1px solid silver; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-2.png" />
				<br /> <br /> <b> Nathalie clique sur l'icône Tiers de confiance</b><br />
			</div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-3.png" /><br /> <br /><b>Nathalie sélectionne Berlcoin, le bouton de transfert apparait </b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-4.png" /><br />
				<br /><b>Nathalie indique le nombre de Berlcoins à séquestrer,la clé publique de Nathalie et valide. </b>
			</div>



			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-6.png" /><br /> <br /><b>Le compte de Nathalie a été débité de 12 Berlcoins</b></div>
		</div>

		<h3 class="mt-12">
			Les tokens de déblocage du séquestre </h3>
		<p> Quelques instants plus tard, Nathalie et Valérie reçoivent leurs tokens de déblocage du séquestre.</p>
		<div class="row ">
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-7.png" /><br /> <br /><b>Le compte de Nathalie </b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-2.png" /><br /> <br /><b>Le compte de Valérie</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-8.png" /><br /> <br /><b>Nathalie réclame le token de déblocage</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-3.png" /><br /> <br /><b>Valérie réclame le token de déblocage</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-9.png" /><br /> <br /><b>Nathalie dispose d'un token de déblocage</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-4.png" /><br /> <br /><b>Valérie dispose d'un token de déblocage</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-10.png" /><br /> <br /><b>Nathalie pourra le transférer à Valérie lorsqu'elle aura reçu les illustrations.</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-5.png" /><br /> <br /><b>Valérie peut le renvoyer à Nathalie si elle n'était plus en mesure de réaliser les illustrations.</b></div>
		</div>
		<div class="row ">
			<h3 class="mt-12">
				Nathalie valide définitivement de la transaction </h3>
			<p> Les dessins sont magnifiques et vont parfaitement illustrer le conte fantastique de Nathalie</p>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-11.png" /><br /> <br /><b>Nathalie valide la transaction.</b></div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-a-12.png" /><br /> <br /><b>Nathalie n'a plus de jeton de déblocage du séquestre.</b></div>
		</div>




		<div class="row ">
			<h3 class="mt-12">
				Valérie récupére le séquestre</h3>
			<p> Nathalie à renvoyé son token de déblocage de séquestre à Valérie qui peut désormais demander à récupérer les 12 Berlcoins </p>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-7.png" /><br /> <br /><b>Nathalie à désormais les deux tokens de déblocage.</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-8.png" /><br /> <br /><b>Nathalie valide la demande de déblocage du séquestre.</b></div>

			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-9.png" /><br /> <br /><b>Nathalie n'avait pas Berlcoins avant, une ligne de confiance est donc créée afin qu'elle puisse en recevoir.</b></div>
			<div class="col-6 col-md-6  mb-4"><img style="border: 1px solid silver ; max-width:100%"
						 src="https://media.berlcoin.fr/compte-B-10.png" /><br /> <br /><b>Nathalie reçoit les Berlcoins quelques minutes plus tards.</b></div>



		</div>
		<div class="row ">
			<h3 class="mt-12">
				Coût de l'opération</h3>
			<p> Nathalie a payé 1 XLM (ici à 0.19 euro pour 1 XLM ) pour le dépôt, puis 3 frais de gas (dépôts, récupération du token de déblocage et transfert à Valérie) soit 0.00003 XLM
				<br /> Valérie a payé 1 XLM (ici à 0.19 euro) pour la récupération du séquestre, puis 2 frais de gas (récupération du token de déblocage et récupération du séquestre) soit 0.002 XLM
				<br /> <br /> Au final moins de 20 centimes d'euros chacune pour garantir une transaction entre la blockchain et le monde réel.
			</p>
		</div>
	</div>

</section>
</template>


<script>
export default {
	name: 'account',
	components: {},
	props: {},
	data: () => ({}),
	beforeCreate: function () {},
	created: function () {},
	beforeMount: function () {},
	mounted: function () {},
	beforeUpdate: function () {},
	updated: function () {},
	beforeDestroy: function () {},
	destroyed: function () {},
	computed: {},
	methods: {},
	watch: {}
}
</script>

<style scoped>
</style>
